import React, { useContext, useEffect, useState } from "react";
import { UserContext } from 'contexts/userContext';
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { DateField } from "@refinedev/mantine";

import {
Grid, //

Box, Group, // List,
ScrollArea, Table, Pagination, Select, Text, Textarea, Chip, Button, Avatar
} from "@mantine/core";

import { List } from "components/ghettoList";

import { ColumnSorter, ColumnFilter, ClientSelect } from '../../../components';
import { list_fields, resource, columnsIdsToFilters, sortersToFilter, dynamicFiltersThunk, generateLink } from "./common";
import { useList, useGetIdentity, useGo, useExport, CrudFilter } from "@refinedev/core";

import dayjs from "dayjs";
import { isAffectrixClientId } from "utilities";

function renderDate(thing:any) {
  const { getValue } = thing
  return getValue() && dayjs(getValue()).isValid() ? (
    <DateField format="MM-DD-YY" value={getValue()} />
  ) : (
    <>N/A</>
  );
};

const metaM = {
  table: 'referral'
}

const columns: ColumnDef<any>[] = [
    // { accessorFn: (row:any) => {
    //   const addedToColaborate = row?.patient?.added_to_collaborate ? "X" : "";
    //   const numberOfDaysToNotify = row?.column_b || "";
    //   return addedToColaborate || numberOfDaysToNotify;
    // }, id: 'patient.added_to_collaborate', header: 'X', enableColumnFilter: false, enableSorting: false,},
    // { accessorKey: 'column_d', id: 'column_d', header: '!', enableColumnFilter: false, enableSorting: false,},
    // { 
    //   // accessorFn: (row) => {
    //   //   return row?.agreement
    //   // },
    //   accessorKey: 'agreement',
    //   id: 'agreement', header: 'C', enableColumnFilter: false, enableSorting: false
    // },
    // { accessorKey: 'product_type.title', id:'product_type.title', header: 'Product Type', enableColumnFilter: false, enableSorting: false},
    
    { accessorKey: 'patient.name_and_dob', id:'patient.name_and_dob', header: 'NAME', enableColumnFilter: true, enableSorting: false, meta: {
        filterOperator: 'contains'
    },
    size: 300},
    // {
    //   header: 'USERS', id: 'patient.meta', 
    //   accessorKey: 'patient.meta',
    //   cell: (thing:any) => {
    //     const { row: { original } } = thing;
    //     const meta = original?.patient?.meta || {};
    //     const userActivity = meta?.userActivity || [];

    //     // @ts-ignore
    //     const activeUsers = userActivity.reduce( (prev, curr) => {
    //       const isWithinFifteenMinutes = 
    //       dayjs().subtract(15, 'minute').isBefore(curr.timestamp);  
    //       if (isWithinFifteenMinutes){
    //         prev.push(curr)
    //       }
    //       return prev
    //     }, [])

    //     const activeUserAvatars = activeUsers.map( (user:any) => <Avatar
    //       radius="md"
    //       // @ts-ignore
    //       src={user?.picture}
    //       imageProps={{ referrerPolicy: "no-referrer" }}
    //     />);

    //     console.log('hello')
        
    //     return <Group>
    //       { activeUserAvatars }
    //     </Group>
    //   }
    // },
    // { accessorKey: 'patient.date_of_birth', id:'patient.date_of_birth', header: 'DOB', enableColumnFilter: true, enableSorting: false,
    //   cell: renderDate,
    //   meta: {
    //     filterElement: DatePicker
    //   }},
    {
      enableColumnFilter: false,
      accessorFn: (row:any) => {
        return row?.product_type?.title
      }, 
      id: 'product_type.title', header: 'PRODUCT', enableSorting:false, meta: {filterOperator: 'contains'}
    },
    {
      accessorKey: "rx_received_date",
      id: "rx_received_date",
      header: "REC'D",
        // minSize: 220,
        // size: 350,
      cell: renderDate,
      enableColumnFilter: false,
      enableSorting: false,
      size: 100
  },
  // { accessorKey: "status", id: "status", header: "STATUS", enableColumnFilter: false, enableSorting: false,},
  //TODO separate in DB, combine for view
  {
      accessorKey: "date_of_surgery",
      id: "date_of_surgery",
      header: "DOS",
        // minWidth: 220,
      accessorFn: (row:any) => {
        let dos = row?.date_of_surgery;
        if (dos && dayjs(dos).isValid() ){
          dos = dayjs(dos).format('MM-DD-YY');
        }
        const condition = row?.condition;
        return dos || condition || 'N/A'
      },
      // cell: renderDate,
      enableColumnFilter: false,
      enableSorting: false,
      size: 100
  },
  {
    accessorKey: "fourteen_day_call",
    id: "fourteen_day_call",
    header: "14D CALL",
      // minWidth: 220,
    cell: renderDate,
    // cell: renderDate,
    enableColumnFilter: false,
    enableSorting: false,
    size: 100
  },
  { 
    accessorFn: (row:any) => {
        return [ row?.insurance?.title, row?.claim_type?.title ]
        .filter(x=>x)
        .reduce((prev,curr) => {
          if (!prev.includes(curr)) prev.push(curr)
          return prev;
        },[])
        .join(' ')
    },
    // accessorKey: 'insurance.title', 
    id:'claim', header: 'CLAIM', enableColumnFilter: false, enableSorting: false, meta: {
        filterOperator: 'contains'
    }
  },
    { 
      accessorFn: (row:any) => {
          return row?.patient_status?.title
      },
      // accessorKey: 'patient_status.title', 
      id:'patient_status.title', header: 'PATIENT STATUS', enableColumnFilter: false, enableSorting: false, meta: {filterOperator: 'contains'}
    },
    { 
      accessorFn: (row:any) => {
          return row?.insurance_status?.title
      },
      accessorKey: 'insurance_status.title',
      meta: {
          filterOperator: 'contains'
      },
      id:'insurance_status.title', header: 'INSURANCE STATUS', enableColumnFilter: false, enableSorting: false
    },
]
.map( x => {
  const { meta } = x;
  return {
    ...x,
    meta: {
      ...meta,
      ...metaM
    }
  }
});

function RadioSelect(props:any) {

    const {
      // user_filters = [],
      filters = [],
      uid,
      showAll = false,
      maybeFiltersForThisUser = false,
    //   tableFilters,
      currentUserState,
      setCurrentUserState
    } = props;

    // user_filters <~> filters
    const mappedFilters = filters
    // @ts-ignore
    .filter( x => {
      const {
        table
      } = x;
      return table == 'referral'
    })
    // @ts-ignore
    .filter( filter => {
      const noFiltersAssignedToThisUser = !maybeFiltersForThisUser;
      if (noFiltersAssignedToThisUser){
        return true
      }

      const { user_filters = [] } = filter;
      // @ts-ignore
      const userHasThisFilter = user_filters.some( user_filter => {
        const { authed_user: {id}} = user_filter;
        return id == uid
      })

      return userHasThisFilter || showAll
    })
    .map( (x:any) => {
      let {
        title,
        filter
      } = x;

      return {
        value: title,
        label: title,
        active_filter: filter,
      }
    })
    // @ts-ignore
    .sort( (a,b) => {
      const {
        label: la
      } = a;
      const {
        label: lb
      } = b;
      return la > lb ? 1 : -1
    })

    const handleChange = (selectValue:any) => {

        if (!selectValue){
            setCurrentUserState({
              ...currentUserState,
              referralFilter: undefined
            })
        }

        const thing = mappedFilters.filter( ({value}:any) => value == (selectValue)) || [{active_filter: false}];

        if (thing.length == 1){

            const [ {active_filter} ] = thing

            setCurrentUserState({
              ...currentUserState,
              referralFilter: {
                title: selectValue,
                active_filter
              }
            })
        } else {
            // console.log('dumb')
            // console.log('')
        }

    };

    return (
        <>
            <Select 
              label={ showAll ? 'All Filters' : 'Filters assigned to User' } 
              data={mappedFilters} onChange={handleChange}
              defaultValue={currentUserState?.referralFilter?.title}
              searchable clearable 
              style={{ maxWidth: 300 }}>
                
            </Select>
        </>
    );
  }

function SortSelect(props:any) {

  const {
    setSorter
  } = props;

  const [value, setValue] = React.useState([]);

  return <>
    <Text>Sort by Latest Type of Note</Text>
    <Chip.Group
      multiple
      value={value}
      onChange={(e) => {
        // set Sorters
    
        // @ts-ignore
        let mutatedValue = [];

        if (e.length == 1){
          mutatedValue = e;
        } if (e.length == 2){
          mutatedValue = e.filter( x => {
            // remove the previous value; only one sorter can be activated
            // @ts-ignore
            return !value.includes(x);
          })
        }

        let sorter: any = { order: 'desc'};
        if (mutatedValue.length == 1){
          // @ts-ignore
          const [noteType] = mutatedValue;

          if (noteType == 'COMM'){
            sorter.field = 'patient.latest_timestamp_of_comm_note';
          } else if (noteType == 'VER'){
            sorter.field = 'patient.latest_timestamp_of_ver_note';
          } else if (noteType == 'BILL'){
            sorter.field = 'patient.latest_timestamp_of_bill_note';
          } else {
            console.log(`Unhandled value for ${noteType}`)
          }
        } else if (mutatedValue.length == 0){
          sorter.field = 'patient.latest_timestamp_of_any_note';
        }

        if (Object.keys(sorter).length == 2){
          setSorter([sorter]);
        }
        

        // @ts-ignore
        setValue(mutatedValue)
        return
      }}
    >
      <Chip value='COMM'>COMM</Chip>
      <Chip value='VER'>VER</Chip>
      <Chip value='BILL'>BILL</Chip>
    </Chip.Group>
  </>
}

export const ReferralList: React.FC = () => {

  const [ columnState, setColumnState ] = useState(columns);

  // @ts-ignore
  const { userState, setUserState } = useContext(UserContext);

  const go = useGo();

  const {
    data: {
      // @ts-ignore

      claims: {
        "x-hasura-user-id": uid = '',
        "x-hasura-client-id": clientId = ''
      } = {}
    } = {}
  } = useGetIdentity({});

  const [ shouldQuery, setShouldQuery ] = useState(false);

  const {
    getHeaderGroups,
    getRowModel,
    refineCore: { 
      setCurrent,
      setFilters,
      filters,
      setSorters,
      sorters,
      pageCount, 
      current,
      tableQueryResult: { data: tableData },
    },
  } = useTable({
    // @ts-ignore
    columns: columnState.filter( x => x?.meta?.visible),

    refineCoreProps: {
      // initialPageSize: 10,
      resource,

      metaData: {
        fields: list_fields
      },
      filters: {
        permanent: [
          {
            field: 'archive',
            operator: 'eq',
            value: false
          }
        ]
      },
      sorters: {
        initial: [
          {
            field: 'created_at',
            order: 'desc'
          }
        ]
      },
      queryOptions: {
        // disable query if the user belongs to the AFX client and the client of the query is undefined
        enabled: shouldQuery,
      },
      pagination: {
        pageSize: 50
      },
    }
  });

  let currentFilter = userState?.referralFilter?.title;

  useEffect( () => {
    // disable until construction of query is complete
    // setShouldQuery(false);

    currentFilter = userState?.referralFilter?.title;
    //sorters and columns based on current filter

    //@ts-ignore
    const columnsForCurrentFilter = columnsIdsToFilters[currentFilter] || columnsIdsToFilters['DEFAULT'];
    // @ts-ignore
    setColumnState(prev => prev.map((col) => {
      // @ts-ignore
      const visible = columnsForCurrentFilter.some( colId => col.id == colId);
      return {
        ...col,
        meta: {
          ...col.meta,
          visible
        }
      }
    }));

    // @ts-ignore
    const sortersForCurrentFilter = sortersToFilter[currentFilter] || sortersToFilter['DEFAULT'];
    setSorters(sortersForCurrentFilter)

    // setShouldQuery(true);

  }, [userState, clientId]);

  // the filters to set upon table are computed as a 
  // function of the view, client, and dynamic logic
  useEffect( () => {
    setShouldQuery(false);
    let newFilterState = [] as any[];

    // filter view
    currentFilter = userState?.referralFilter?.title;
    if (currentFilter){
      let filterView = userState.referralFilter?.active_filter;
      if (filterView?.length > 0){
        newFilterState.push(...filterView);
      }
    }

    // client filter
    if (isAffectrixClientId(clientId) && userState?.clientId){
      newFilterState.push({
        field: 'client_id',
        operator: 'eq',
        value: userState?.clientId
      })
    } else if (clientId) {
      newFilterState.push({
        field: 'client_id',
        operator: 'eq',
        value: clientId
      }); 
    }

    // exclude referrals acted upon (via next call date)
    const dynamicFilters = dynamicFiltersThunk();
    // @ts-ignore
    const dynamicFiltersToAdd = dynamicFilters[currentFilter] || dynamicFilters['DEFAULT'];

    newFilterState.push(...dynamicFiltersToAdd);

    setFilters(newFilterState, 'replace');

    if (clientId){
      // behavior specific to AFX/STRIVE users
      if (isAffectrixClientId(clientId)){
        const hasName = newFilterState.some( ({field,value}:any) => 
          field == 'patient.name_and_dob' &&
          value.length > 0
        );
        const hasClient = newFilterState.some( ({field,value}:any) => 
          field == 'client_id' &&
          value.length > 0
        );
        if (hasName || hasClient){
          setShouldQuery(true);
        }
      } else {
        setShouldQuery(true);
      }
    }

    // setShouldQuery(true);
  }, [ clientId, userState?.clientId, userState?.referralFilter])

  // Export Button
  const { triggerExport, isLoading } = useExport({
    mapData: (item) => {
      let dos = item?.patient?.date_of_surgery;
      if (dos && dayjs(dos).isValid() ){
        dos = dayjs(dos).format('MM-DD-YY');
      }
      const condition = item?.patient?.condition;
      const DOS = dos || condition || 'N/A';
      const mapped = {
        X: item?.patient?.added_to_collaborate || '',
        '!': item?.column_d || '',
        C: item?.agreement || '',
        Name: item?.patient?.name_and_dob,
        Product: item?.product_title,
        'Patient Status': item?.patient_status?.title,
        'Insurance': item?.insurance?.title,
        'Claim Type': item?.claim_type?.title,
        'Insurance Status': item?.insurance_status?.title,
        "Rec'd": item?.rx_date || 'N/A',
        DOS
      }
      return mapped
    },
    filters,
    pageSize: 50,
    meta:{
      fields: list_fields
    }
  });

  let {
    data: {
        // @ts-ignore
        data: dataFilters = []
    } = {}
  } = useList({
    resource: 'filter',

    meta: {
        fields: [
            'title',
            'filter',
            'table',
            {
              user_filters: [
                {
                  authed_user: [
                    'id', 'email', 'display_name'
                  ]
                }
              ]
            }
        ],
    },

    filters: [
      {
        field: 'table',
        operator: 'eq',
        value: 'referral'
      }
    ],

    pagination:{
        pageSize: 100
    }
  });

  dataFilters = [ ...dataFilters, {
    table: 'referral',
    title: 'ARP > 30 DAYS',
    filter: [
      {
        field: "insurance_status.title",
        operator: "iregex",
        value: "URGENT - AUTH REQUEST PENDING|AUTH REQUEST PENDING|APPEAL 1|APPEAL 2"
      },
      {
        field: "insurance_status_updated_at",
        operator: "lte",
        value: dayjs().subtract(30, 'day').format('YYYY-MM-DD')
      }
    ]
  }]

  // @ts-ignore
  const maybeFiltersForThisUser = dataFilters.filter( x => {
    const {
      table
    } = x;
    return table == 'referral'
  })
  // @ts-ignore
  .some( filter => {
    const { user_filters = [] } = filter;
    // @ts-ignore
    const userHasThisFilter = user_filters.some( user_filter => {
      const { authed_user: {id}} = user_filter;
      return id == uid
    })

    return userHasThisFilter
  })

  return (
    <ScrollArea>
      <List>
          { (
            userState?.referralFilter?.title &&
            maybeFiltersForThisUser
          ) ?? <Text>{userState?.referralFilter?.title}</Text>}
          <Grid>
            {maybeFiltersForThisUser && <Grid.Col span={4}>
              <RadioSelect 
              uid={uid}
              filters={dataFilters}

              maybeFiltersForThisUser

              currentUserState={userState}
              setCurrentUserState={setUserState}
              ></RadioSelect>
            </Grid.Col>}
            <Grid.Col span={4}>
              <RadioSelect 
              uid={uid}
              filters={dataFilters}
              showAll={true}

              currentUserState={userState}
              setCurrentUserState={setUserState}
              ></RadioSelect>
            </Grid.Col>
            <Grid.Col span={4}>
              <Button onClick={triggerExport} disabled={isLoading}>
                Download
              </Button>
            </Grid.Col>
            {/* <Grid.Col span={4}>
              <SortSelect
                setSorter={setSorter}
                sorter={sorter}
              ></SortSelect>
            </Grid.Col> */}
          </Grid>
          <Table highlightOnHover>
              <thead>
                  {getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            const width = header?.column?.columnDef?.size;
                            let style = {} as any;
                            if (width != 150){
                              style.width = width;
                            }
                              return (
                                  <th key={header.id} style={style}>
                                      {!header.isPlaceholder && (
                                          <Group spacing="xs" noWrap>
                                              <Box>
                                                  {flexRender(
                                                      header.column
                                                          .columnDef
                                                          .header,
                                                      header.getContext(),
                                                  )}
                                              </Box>
                                              <Group spacing="xs" noWrap>
                                                  <ColumnSorter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                                  <ColumnFilter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                              </Group>
                                          </Group>
                                      )}
                                  </th>
                              );
                          })}
                      </tr>
                  ))}
              </thead>
              <tbody>
                  {getRowModel().rows.map((row) => {

                      let rowColor = 'transparent';

                      const original = row.original;
                      const pro_reps = original?.provider?.provider_representatives || [];
                      const hasHink = pro_reps.some( (x:any) => x.representative.name.includes('HINK'));
                      const isOhioWC = original?.claim_type?.title.includes('OH WC');
                      const isTCT = original?.product_type?.title.includes('TCT');
                      if ( hasHink && isOhioWC && isTCT ){
                        rowColor = "#f2f2f2";  // #light grey
                      }

                      return (
                          <tr style={{
                            background: rowColor
                          }} key={row.id} onClick={() => {
                            go(generateLink({ row, filter: currentFilter}));
                          }
                            }>
                              {row.getVisibleCells().map((cell) => {
                                const cellValue = cell.getValue() || "";

                                const cDef = cell?.column?.columnDef?.id || '';
                                //column.columnDef.id
                                let columnColor = 'transparent';
                                if (
                                  ['patient_status','insurance_status'].some( x => cDef.includes(x)) &&
                                  /URGENT/.test(cellValue as string)
                                ){
                                  columnColor = 'red';
                                }

                                if (
                                  cDef == 'patient_status.title' && 
                                  cellValue == 'APPROVED TO SHIP' &&
                                  ['N/A',"REC'D"].some( x => row.original?.document_Status?.title == x) 
                                ){
                                  columnColor = 'red';
                                }

                                if (
                                  cDef == 'provider.name' &&
                                  (
                                    /OTA|PTA/.test(cellValue as string) ||
                                    (
                                      /DC/.test(cellValue as string) &&
                                      /PA/.test(row.original?.claim_type?.title)
                                    )
                                  )
                                ){
                                  columnColor = 'red';
                                }

                                if (
                                  cDef == 'claim_type.title' &&
                                  /FP2A/.test(cellValue as string)
                                ){
                                  columnColor = 'yellow';
                                }

                                if (
                                  cDef == "insurance.title" &&
                                  (cellValue as string).includes('/PO')
                                ){
                                  columnColor = '#f2f2f2'; // #light grey
                                }

                                if (
                                  ['insurance.title','claim_type.title'].some( x => cDef.includes(x)) &&
                                  /LITIGATION/.test(cellValue as string)
                                ){
                                  columnColor = 'red';
                                }

                                const insReg = /ERIE|MEDICARE|MEDICAID|JOHNSTON & ASSOCIATES|SENTRY|TRICARE|UPMC|HARTFORD|ROCKWOOD|PINNACOL|LACKAWANNA|SWIF|BASIS|EXCALIBUR/;
                                const proReg = / PT| DPT| MPT| RPT| DC/;
                                if (
                                  cDef == "insurance.title" && (
                                    (
                                      insReg.test(cellValue as string) && proReg.test(row?.original?.provider?.name)
                                    ) || (
                                      /WALMART/.test(cellValue as string) && proReg.test(row?.original?.provider?.name)
                                    )
                                  )
                                ){
                                  columnColor = 'magenta';
                                }

                                  return (
                                      <td 
                                      style={{
                                        background: columnColor
                                      }}
                                      key={cell.id}>
                                          {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext(),
                                          )}
                                      </td>
                                  );
                              })}
                          </tr>
                      );
                  })}
              </tbody>
          </Table>
          <br />
          <Pagination
              position="right"
              total={pageCount}
              page={current}
              onChange={setCurrent}
          />
      </List>
  </ScrollArea>
  );
};
